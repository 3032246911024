import React, {useState} from "react"
import { navigate } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Modal} from "react-bootstrap";
import MediaLayout from "../../components/media-layout"
import {useIntl, Link} from "gatsby-plugin-intl"

import Legal from "../../pages/legal"

class BodyClass extends React.Component {
  static propTypes = {
    name: React.PropTypes
  }
  static defaultProps = {
    name: ''
  }
  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}

const AboutYouPlus = () => {
  const intl = useIntl();
  const [modalShow, setModaleShow] = useState(false);

  const move = () => {
    navigate("/rewards/reward-list")
  }

  const handleClose = () => {
    setModaleShow(false);
  };

  const toggleModale = (e) => {
    e.preventDefault();
    setModaleShow(true)
  }

  const pageContent = () => {
    return(
      <div className="col-lg-8">
        <div className="mobile-container">
          <Link to="/rewards/reward-list" className="black mb-4 mt-0 back-link"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "about-youplus.back" })}</Link>
        </div>

        <div className="white-container about-youplus">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20">{intl.formatMessage({ id: "about-youplus.main-title1" })}</h1>

          


          <div className="accordion" id="improve-status">
            <h3 className="mb-0"><a href="#" className="d-block collapsed"
                                                                   data-toggle="collapse"
                                                                   data-target="#collapse-improve-status"
                                                                   aria-expanded="false"
                                                                   aria-controls="collapse-improve-status">{intl.formatMessage({ id: "about-youplus.main-title2" })}</a></h3>
            <div className="card">
              <div id="collapse-improve-status" className="collapse" aria-labelledby="headingTwo"
                   data-parent="#improve-status">
                <div className="card-body">

                  <h2>{intl.formatMessage({ id: "about-youplus.title2" })}</h2>

                  <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about-youplus.description2" })}} />

                  <div className="table-loyalty">
                    <ul>
                      <li>
                        <div className=""><span className="icon-win icon-win-text"><span className="path1"></span><span
                          className="path2"></span><span className="path3"></span><span
                          className="text">{intl.formatMessage({ id: "generic.status_level.1" })}</span></span></div>
                        <h2>{intl.formatMessage({ id: "about-youplus.level_1_points" })}</h2></li>
                      <li>
                        <div className=""><span className="icon-win silver icon-win-text"><span
                          className="path1"></span><span className="path2"></span><span className="path3"></span><span
                          className="text">{intl.formatMessage({ id: "generic.status_level.2" })}</span></span></div>
                        <h2>{intl.formatMessage({ id: "about-youplus.level_2_points" })}</h2></li>
                      <li>
                        <div className=""><span className="icon-win gold icon-win-text"><span
                          className="path1"></span><span className="path2"></span><span className="path3"></span><span
                          className="text">{intl.formatMessage({ id: "generic.status_level.3" })}</span></span></div>
                        <h2>{intl.formatMessage({ id: "about-youplus.level_3_points" })}</h2></li>
                      <li>
                        <div className=""><span className="icon-win platinum icon-win-text"><span
                          className="path1"></span><span className="path2"></span><span className="path3"></span><span
                          className="text">{intl.formatMessage({ id: "generic.status_level.4" })}</span></span></div>
                        <h2>{intl.formatMessage({ id: "about-youplus.level_4_points" })}</h2></li>
                    </ul>
                  </div>

                  <h2 id="title3">{intl.formatMessage({ id: "about-youplus.title3" })}</h2>

                  <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about-youplus.description3" })}} />


                  <h2>{intl.formatMessage({ id: "about-youplus.title4" })}</h2>

                  <div className="table-responsive-md mb-5">

                    <table className="table">
                      <thead>
                        <tr>
                          <th>{intl.formatMessage({ id: "about-youplus.table.row1.cell1" })}</th>
                          <th>{intl.formatMessage({ id: "about-youplus.table.row1.cell2" })}</th>
                          <th>{intl.formatMessage({ id: "about-youplus.table.row1.cell3" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row2.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row2.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row2.cell3"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row3.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row3.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row3.cell3"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row4.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row4.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row4.cell3"})}</td>
                      </tr>
                      <tr>
                        <td rowSpan="2" className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row5.cell1"})}</td>

                        <td>{intl.formatMessage({id: "about-youplus.table.row5.cell2.1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row5.cell3.1"})}</td>
                      </tr>
                      <tr>
                        <td>{intl.formatMessage({id: "about-youplus.table.row5.cell2.2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row5.cell3.2"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row6.cell1"})}</td>
                        <td><div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about-youplus.table.row6.cell2" })}} /></td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row6.cell3"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row7.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row7.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row7.cell3"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row8.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row8.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row8.cell3"})}</td>
                      </tr>
                      <tr>
                        <td className="table-first-cell">{intl.formatMessage({id: "about-youplus.table.row9.cell1"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row9.cell2"})}</td>
                        <td>{intl.formatMessage({id: "about-youplus.table.row9.cell3"})}</td>
                      </tr>
                      </tbody>
                    </table>

                  </div>

                  <p className="small">{intl.formatMessage({ id: "about-youplus.asterisque" })}</p>
                  <p className="small">{intl.formatMessage({ id: "about-youplus.asterisque2" })}</p>


                </div>
              </div>
            </div>
          </div>


          <div className="accordion" id="avantages">
            <h3 className="mb-0">
                  <a href="#" className="d-block collapsed"
                     data-toggle="collapse"
                     data-target="#collapse-avantages"
                     aria-expanded="false"
                     aria-controls="collapse-avantages">{intl.formatMessage({ id: "about-youplus.title5" })}</a>
                </h3>
            <div className="card">
              <div id="collapse-avantages" className="collapse" aria-labelledby="headingTwo" data-parent="#avantages">
                <div className="card-body">

                  <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about-youplus.description5" })}} />
                  <h2>{intl.formatMessage({ id: "about-youplus.title6" })}</h2>

                  <div dangerouslySetInnerHTML={{__html: intl.formatMessage({ id: "about-youplus.description6" })}} />

                </div>
              </div>
            </div>
          </div>


          <div className="text-center small pt-5"><Link to="#" className="mx-md-4" onClick={(e) => toggleModale(e)}>{intl.formatMessage({id: "about-youplus.cgv"})}</Link></div>


        </div>

      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.rewards.about-youplus" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="reward" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>
      <Modal size="lg" centered show={modalShow} onHide={() => handleClose()} animation={false}  id="legals">
        <Modal.Header className="justify-content-center">
          <h2 className="modal-title text-uppercase flex-grow-1 text-center" id="exampleModalCenterTitle">{intl.formatMessage({ id: "footer.terms" })}</h2>
          <button type="button" className="close" onClick={() => handleClose()}>
                <span aria-hidden="true">
                  <span className="icon-close"></span>
                </span>
              </button>
        </Modal.Header>
        <Modal.Body className="text-center px-4 pt-4 pb-4 " >
          <Legal />
          <button class="button red mx-auto mr-md-0" onClick={() => handleClose()}>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</button>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}


export default AboutYouPlus
